import { useContext, useEffect, useState } from "react";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import { getMethod, postMethod } from "../../../utils/apiMethod";
import InputField from "../../general/NewInputField";
import { StateContext } from "../../../Context/StateContext";
import NotificationCheckbox from "../../general/modals/NotificationCheckbox";
import { SendFinanceNotification } from "../../../utils/notificationApis";

function FinanceEdit({ onBackClick }) {
  const { setLoading,profileStatus } = useContext(StateContext);
  const [notificationModal, setNotificationModal] = useState({
    list: [],
    show: false,
  });

  const [financeDetails, setFinanceDetails] = useState({
    ipoStatus: "private",
    latestFundingRound: "",
    averagePurchasePrice: 0,
    financeLink: "",
    revenuePerEmployee: 0,
    revenuePerClient: 0,
    latestValuation: "",
    annualRevenue: 0,
    totalFunding: "",
  });
  const [financeResponse, setFinanceResponse] = useState({});

  const FormatNumber = (number) => {
    // return new Intl.NumberFormat('en-US').format(number);
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  const [otherFundingRound, setOtherFundingRound] = useState("");
  const [otherIpoStatus, setOtherIpoStatus] = useState("");
  const [valuationExists, setValuationExists] = useState(false);
  const [fundingExists, setFundingExists] = useState(false);

  const handleIpoStatusChange = (event) => {
    const updatedFinanceDetails = {
      ...financeDetails,
      ipoStatus: event.target.value,
    };
    setFinanceDetails(updatedFinanceDetails);
  };

  const fetchLatestFundingValue = (value) => {
    const updatedFinanceDetails = {
      ...financeDetails,
      latestFundingRound: value,
    };
    setFinanceDetails(updatedFinanceDetails);
  };
  const fetchAnnualRevenue = (value) => {
    const updatedFinanceDetails = { ...financeDetails, annualRevenue: value };
    setFinanceDetails(updatedFinanceDetails);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let fundingRound = financeDetails?.latestFundingRound;
    let ipo = financeDetails?.ipoStatus;
    if (financeDetails?.latestFundingRound.toLowerCase() == "other") {
      fundingRound = otherFundingRound;
    }
    if (
      financeDetails?.ipoStatus.toLowerCase() !== "public" &&
      financeDetails?.ipoStatus.toLowerCase() !== "private"
    ) {
      ipo = otherIpoStatus;
    }
    let raw = {
      // ipoStatus: ipo,
      // latestFundingRound: fundingRound,
      // annualRevenue: financeDetails.annualRevenue,
    };
    if (ipo !== financeResponse?.ipoStatus) {
      raw = { ...raw, ipoStatus: ipo };
    }
    if (fundingRound !== financeResponse?.latestFundingRound) {
      raw = { ...raw, latestFundingRound: fundingRound };
    }
    if (financeDetails.annualRevenue !== financeResponse?.annualRevenue) {
      raw = { ...raw, annualRevenue: financeDetails.annualRevenue };
    }
    // if (valuationExists && financeDetails.latestValuation && financeDetails.latestValuation!==financeResponse?.latestValuation) {
    //   raw = { ...raw, latestValuation: financeDetails.latestValuation };
    // }
    if (financeDetails.latestValuation !== financeResponse?.latestValuation) {
      raw = { ...raw, latestValuation: financeDetails.latestValuation };
    }

    if (financeDetails.totalFunding !== financeResponse?.totalFunding) {
      raw = { ...raw, totalFunding: financeDetails.totalFunding };
    }

    if (ipo === "public") {
      raw = { ...raw, financeLink: financeDetails.financeLink };
    }
    let rawNew = raw;
    raw = JSON.stringify(raw);
    getMethod(`finance/getMyFinanceInformation`)
      .then((res) => {
        if (!res?.exists) {
          // add
          postMethod(`finance/addFinance`, raw)
            .then((res) => {
              if (res.status == true) {
                toastSuccess(res?.message);
                // onBackClick();
                if(profileStatus === "2") {
                  setNotificationModal({ list: Object.keys(rawNew), show: true });
                }else{
                   onBackClick();
                }
              }
            })
            .catch((error) => {
              // setLoading(false);
              toastError(error.message);
              console.log("error.message: ", error.message);
            });
        } else {
          // update
         
          if (Object.keys(rawNew)?.length > 0){
            postMethod(`finance/updateFinance`, raw)
            .then((res) => {
              if (res.status === true) {
                toastSuccess(res?.message);
                // onBackClick();
                if(profileStatus === "2") {
                  setNotificationModal({ list: Object.keys(rawNew), show: true });
                }else{
                   onBackClick();
                }
              }
            })
            .catch((error) => {
              // setLoading(false);
              console.log("Error here : ", error.message)
              toastError(error.message);
            });
          }else{
            onBackClick();
          }
          
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
      });
  };

  const fetchOtherFundingValue = (e) => {
    setOtherFundingRound(e.target.value);
  };

  useEffect(() => {
    setLoading(true);

    window.scrollTo(0, 0);

    getMethod(`finance/getMyFinanceInformation`)
      .then((res) => {
        setLoading(false);
        if (res?.status === true) {
          console.log("Response finance : ", res.data.latestFundingRound);
          const updatedFinanceDetails = {
            ...financeDetails,
            ipoStatus: res.data?.ipoStatus || "private",
            latestFundingRound: res.data?.latestFundingRound || "",
            averagePurchasePrice: res.data?.averagePurchasePrice || 0,
            financeLink: res.data?.financeLink || "",
            revenuePerEmployee: res.data?.revenuePerEmployee || 0,
            revenuePerClient: res.data?.revenuePerClient || 0,
            latestValuation: res?.data?.latestValuation
              ? FormatNumber(res.data?.latestValuation)
              : "",
            annualRevenue: res.data?.annualRevenue || 0,
            totalFunding: res?.data?.totalFunding
              ? FormatNumber(res.data?.totalFunding)
              : "",
          };
          if (res.data?.latestValuation) setValuationExists(true);
          if (res?.data?.totalFunding) setFundingExists(true);
          setFinanceDetails(updatedFinanceDetails);
          setFinanceResponse(updatedFinanceDetails);
          if (
            res?.data?.ipoStatus !== "private" &&
            res?.data?.ipoStatus !== "public"
          ) {
            setOtherIpoStatus(res.data?.ipoStatus);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <form
      className="p-5 lg:p-8 category-content-wrapper-div"
      onSubmit={submitHandler}
    >
      {/* Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img
                src="assets/arrow-back-circle.svg"
                alt="arrow-back"
                className="cursor-pointer"
              />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">Finance</div>
              <div className="text-grey-2">Add finance details.</div>
            </div>
          </div>

          <div className="flex lg:justify-end mt-5 lg:mt-0">
            <button className="btn-sea-green-thick" type="submit">
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="rounded-xl border border-grey-6 p-8 flex flex-col gap-8">
        {/* Input fields */}
        <div className="grid grid-cols-1 gap-8">
          <div className="flex flex-col gap-4 w-full justify-center">
            <div>
              IPO Status<span className="text-orange-1">*</span>
            </div>

            <div className="xs:flex-col lg:flex gap-5">
              <div className="flex gap-5">
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    id="private"
                    name="ipoStatus"
                    value="private"
                    checked={financeDetails.ipoStatus === "private"}
                    onChange={handleIpoStatusChange}
                  />
                  <label for="private">Private</label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    id="public"
                    name="ipoStatus"
                    value="public"
                    checked={financeDetails.ipoStatus === "public"}
                    onChange={handleIpoStatusChange}
                  />
                  <label for="public">Public</label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    id="other"
                    name="ipoStatus"
                    value="other"
                    checked={
                      financeDetails.ipoStatus !== "public" &&
                      financeDetails.ipoStatus !== "private"
                    }
                    onChange={handleIpoStatusChange}
                  />
                  <label for="other">
                    Other
                    {financeDetails?.ipoStatus.toLowerCase() !== "private" &&
                      financeDetails?.ipoStatus.toLowerCase() !== "public" && (
                        <span className="text-grey-12">
                          &nbsp;( max 30 characters )
                        </span>
                      )}
                  </label>
                </div>
              </div>

              {financeDetails?.ipoStatus.toLowerCase() !== "private" &&
                financeDetails?.ipoStatus.toLowerCase() !== "public" && (
                  <div className="flex items-center rounded-lg border border-grey-7 h-12 mt-3 lg:mt-0 lg:w-1/3">
                    <input
                      className="focus:border-transparent outline-none mx-5 w-full h-full"
                      type="text"
                      placeholder="Other"
                      value={otherIpoStatus}
                      onChange={(e) => setOtherIpoStatus(e.target.value)}
                      required={true}
                      maxLength={25}
                    />
                  </div>
                )}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-5">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <InputField
                label="Latest Funding Round"
                placeholder="Latest Funding Round"
                value={financeDetails?.latestFundingRound}
                isDropdown={true}
                optional={false}
                getSelectedValue={fetchLatestFundingValue}
                dropdownClasses={"uppercase text-sm"}
                options={[
                  "self-funded/angel",
                  "pre-seed",
                  "seed",
                  "series A",
                  "series B",
                  "series C",
                  "series D",
                  "series E",
                  "series F",
                  "other",
                ]}
                width={"w-full"}
              />

              {financeDetails?.latestFundingRound.toLowerCase() === "other" && (
                <InputField
                  label="Other (Latest Funding Round)"
                  placeholder="Other"
                  value={otherFundingRound}
                  onChange={(e) => setOtherFundingRound(e.target.value)}
                  optional={false}
                  width={"w-full"}
                />
              )}
            </div>

            <div className={`flex flex-col gap-4 w-full md:w-1/3`}>
              <div className="font-light">
                Yahoo Finance Link{" "}
                <span className="text-grey-10 text-xs">
                  ( If IPO Status is Public )
                </span>
                {financeDetails?.ipoStatus.toLowerCase() === "public" ? (
                  <span className="text-orange-1">*</span>
                ) : (
                  ""
                )}
              </div>

              <div
                className={`flex items-center rounded-lg border border-grey-7 h-12 ${
                  financeDetails?.ipoStatus.toLowerCase() === "public"
                    ? ""
                    : `bg-grey-5`
                }`}
              >
                <input
                  className={`focus:border-transparent outline-none mx-5 w-full h-full ${
                    financeDetails?.ipoStatus.toLowerCase() === "public"
                      ? ""
                      : `bg-grey-5`
                  }`}
                  placeholder="Link"
                  required={
                    // financeDetails?.ipoStatus.toLowerCase() === "public"
                    //   ? true
                    //   : false
                    false
                  }
                  value={
                    financeDetails?.ipoStatus.toLowerCase() === "public"
                      ? financeDetails?.financeLink
                      : ""
                  }
                  readOnly={
                    financeDetails?.ipoStatus.toLowerCase() === "public"
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    const updatedFinanceDetails = {
                      ...financeDetails,
                      financeLink: e.target.value,
                    };
                    setFinanceDetails(updatedFinanceDetails);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                <div className="font-light">
                  Latest Valuation ($)
                  <span className="text-orange-1">*</span>
                </div>
                <div className="flex gap-2">
                  <div className="flex gap-2 items-center">
                    <input
                      id="ValuationTrue"
                      type="radio"
                      name="valuation"
                      checked={valuationExists == true}
                      onClick={() => {
                        setValuationExists(true);
                      }}
                    />
                    <label for="ValuationTrue">Available</label>
                  </div>
                  <div className="flex gap-2 items-center">
                    <input
                      id="ValuationFalse"
                      type="radio"
                      name="valuation"
                      checked={valuationExists == false}
                      onClick={() => {
                        setValuationExists(false);
                        setFinanceDetails({
                          ...financeDetails,
                          latestValuation: "",
                        });
                      }}
                    />
                    <label for="ValuationFalse">N/A</label>
                  </div>
                </div>
              </div>
              {valuationExists && (
                <input
                  className="rounded-lg border border-grey-7 h-12 w-full px-5"
                  placeholder="Valuation"
                  value={financeDetails?.latestValuation}
                  required={valuationExists}
                  onChange={(e) => {
                    let { value } = e.target;

                    // Remove non-numeric characters except the decimal point
                    value = value.replace(/[^\d.]/g, "");

                    // Ensure the value is greater than zero (but not zero)
                    if (parseFloat(value) > 0 || value === "") {
                      // Format the number with commas
                      const formattedValue = FormatNumber(value);

                      // Update the state only if the value is greater than zero
                      const updatedFinanceDetails = {
                        ...financeDetails,
                        latestValuation: formattedValue,
                      };
                      setFinanceDetails(updatedFinanceDetails);
                    }
                  }}
                  maxLength={1000}
                  type={"text"}
                />
              )}
            </div>

            <InputField
              label="Annual Revenue"
              placeholder="Revenue"
              value={financeDetails?.annualRevenue}
              optional={false}
              isDropdown={true}
              getSelectedValue={fetchAnnualRevenue}
              dropdownClasses={"text-sm"}
              width={"w-full"}
              options={[
                "Less than $1M",
                "$1M - $10M",
                "$10M - $50M",
                "$50M - $100M",
                "$100M - $250M",
                "$250M - $500M",
                "$500M - $1B",
                "$1B - $10B",
                "$10B+",
              ]}
            />

            <div className="flex flex-col gap-4">
              <div className="flex gap-4">
                <div className="font-light">
                  Total Funding ($)
                  <span className="text-orange-1">*</span>
                </div>
                <div className="flex gap-2">
                  <div className="flex gap-2 items-center">
                    <input
                      id="FundingTrue"
                      type="radio"
                      name="funding"
                      checked={fundingExists == true}
                      onClick={() => {
                        setFundingExists(true);
                      }}
                    />
                    <label for="FundingTrue">Available</label>
                  </div>
                  <div className="flex gap-2 items-center">
                    <input
                      id="FundingFalse"
                      type="radio"
                      name="funding"
                      checked={fundingExists == false}
                      onClick={() => {
                        setFundingExists(false);
                        setFinanceDetails({
                          ...financeDetails,
                          totalFunding: "",
                        });
                      }}
                    />
                    <label for="FundingFalse">N/A</label>
                  </div>
                </div>
              </div>
              {fundingExists && (
                <input
                  className="rounded-lg border border-grey-7 h-12 w-full px-5"
                  placeholder="Funding"
                  value={financeDetails.totalFunding}
                  required={fundingExists}
                  onChange={(e) => {
                    let { value } = e.target;

                    // Remove non-numeric characters except the decimal point
                    value = value.replace(/[^\d.]/g, "");

                    // Ensure the value is greater than zero (but not zero)
                    if (parseFloat(value) > 0 || value === "") {
                      // Format the number with commas
                      const formattedValue = FormatNumber(value);

                      const updatedFinanceDetails = {
                        ...financeDetails,
                        totalFunding: formattedValue,
                      };
                      setFinanceDetails(updatedFinanceDetails);
                    }
                  }}
                  maxLength={1000}
                  type={"text"}
                />
              )}
            </div>
          </div>

          <div className="flex gap-5"></div>
        </div>
      </div>

      {/* Save changes button */}

      <div className="flex flex-row-reverse">
        <div className="flex lg:justify-end w-full lg:w-2/12">
          <button className="btn-sea-green text-xl w-full" type="submit">
            Save
          </button>
        </div>
      </div>

      {notificationModal?.show && (
        <NotificationCheckbox
          list={notificationModal?.list}
          message="Here's a summary of your latest financial updates. Select which changes you'd like to notify your followers about :"
          onBackClick={onBackClick}
          setNotificationModal={setNotificationModal}
          notificationHandler={SendFinanceNotification}
        />
      )}
    </form>
  );
}

export default FinanceEdit;