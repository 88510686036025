import React, { useContext, useState } from "react";
import { StateContext } from "../../../Context/StateContext";
import { postMethod } from "../../../utils/apiMethod";
import { toastError } from "../../../utils/Toasters";
import SuccessModal from "../../general/modals/SuccessModal";

const ManageInvitations = () => {
  const { setLoading } = useContext(StateContext);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    email: "",
    role: "editor",
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    const payload = JSON.stringify(details);
    try {
      let response = await postMethod(`companyUser/onboard`, payload);
      if (response?.status === true) {
        setLoading(false);
        setShowSuccessModal(true);
        setDetails({ name: "", email: "", role: "editor" });
      } else {
        setLoading(false);
        toastError(response?.message);
      }
    } catch (error) {
      setLoading(false);
      toastError(error?.message);
    }
  };
  return (
    <form
      className="p-5 lg:p-8 category-content-wrapper-div gap-5"
      onSubmit={submitHandler}
    >
      {/* header */}
      <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
        <div className="flex flex-col">
          <div className="font-medium text-[28px]">Add Role</div>
          <div className="text-grey-2 text-[16px]">Add User Details</div>
        </div>
      </div>

      {/* Body */}
      <div className="flex flex-col gap-7">
        <div className="flex flex-col gap-4">
          <div>
            Name <span className="text-orange-1">*</span>
          </div>
          <div className="flex items-center rounded-lg border border-grey-7 h-12">
            <input
              className="focus:border-transparent outline-none mx-5 w-full h-full"
              type="text"
              value={details?.name}
              onChange={(e) => setDetails({ ...details, name: e.target.value })}
              required={true}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            Email ID <span className="text-orange-1">*</span>
          </div>
          <div
            className={`flex items-center rounded-lg border border-grey-7 h-12`}
          >
            <input
              className={`focus:border-transparent outline-none mx-5 w-full h-full`}
              type="email"
              value={details?.email}
              onChange={(e) =>
                setDetails({ ...details, email: e.target.value })
              }
              required={true}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            Permissions <span className="text-orange-1">*</span>
          </div>
          <div className={`flex items-center gap-5`}>
            <div className="flex gap-2">
              <input
                name="permission"
                type="radio"
                id="editor"
                value="editor"
                onChange={(e) =>
                  setDetails({ ...details, role: e.target.value })
                }
                className="cursor-pointer"
                checked={details?.role === "editor"}
              />
              <label for="editor" className="cursor-pointer">
                Edit
              </label>
            </div>
            <div className="flex gap-2">
              <input
                name="permission"
                type="radio"
                id="viewer"
                value="viewer"
                onChange={(e) =>
                  setDetails({ ...details, role: e.target.value })
                }
                className="cursor-pointer"
                checked={details?.role === "viewer"}
              />
              <label for="viewer" className="cursor-pointer">
                View
              </label>
            </div>
          </div>
        </div>
        <div className="flex lg:justify-end mt-5 lg:mt-0">
          <button className="btn-sea-green-thick cursor-pointer" type="submit">
            Send Invite
          </button>
        </div>
      </div>
      {showSuccessModal && (
        <SuccessModal
          message={"Invitation email sent successfully."}
          setShowModal={setShowSuccessModal}
        />
      )}
    </form>
  );
};

export default ManageInvitations;