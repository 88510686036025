import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getOnBoardCookie, setOnBoardCookie, setToken } from "../auth/cookies";
import useFirebaseAuth from "../auth/useFirebaseAuth";
import Footer from "./general/NewFooter";
import LoginForm from "./subPages/LoginForm";

function HomePage() {

  const [openHeaderModal, setOpenHeaderModal] = useState(false)

  const navigate = useNavigate();

  const joinNowHandler = () => {
    navigate("/register");
  };

  const handleScrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const isLogin = getOnBoardCookie();
    if (isLogin) {
      navigate("/company?activePage=dashboard");
    }
  }, []);

  useEffect(() => {
   
    window.scrollTo(0,0);

}, [])

  return (
    <div className="flex flex-col bg-white">
      {/* HEADER */}

      {/* Desktop View header */}
      <div className=" justify-between m-auto w-10/12 py-10 hidden md:flex">
        <div className="w-1/6">
          <img src="assets/FutrLogo.png" alt="brand-logo" />
        </div>
        <div className="flex gap-20 items-center">
          <div
            className="text-xl text-dark-blue-1 font-light cursor-pointer"
            onClick={(e) => handleScrollToSection(e, "spotlight")}
          >
            Showcase
          </div>
          {/* <a href="/pricing">
          <div
            className="text-xl text-dark-blue-1 font-light cursor-pointer"
          >
           Pricing
          </div>
          </a> */}
          <a href="#whoFutrIsFor">
            <div
              className="text-xl text-dark-blue-1 font-light cursor-pointer"
              onClick={(e) => handleScrollToSection(e, "whoFutrIsFor")}
            >
              All About the futr
            </div>
          </a>
          <div
            className="text-xl text-dark-blue-1 font-light cursor-pointer"
            onClick={joinNowHandler}
          >
            Join Now
          </div>
          {/* <div>
                <button className="bg-sea-green text-white text-xl font-light border-none rounded-2xl px-10 py-4">Sign In</button>
            </div> */}
        </div>
      </div>

      {/* Mobile View header */}
      <div className="grid grid-cols-3 m-auto w-full md:hidden p-5">

        <div className="relative" onClick={() => setOpenHeaderModal(!openHeaderModal)}>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.91775 32.0824C5.42216 34.5834 9.447 34.5834 17.5001 34.5834C25.5532 34.5834 29.5797 34.5834 32.0807 32.0807C34.5834 29.5814 34.5834 25.5532 34.5834 17.5001C34.5834 9.447 34.5834 5.42046 32.0807 2.91775C29.5814 0.416748 25.5532 0.416748 17.5001 0.416748C9.447 0.416748 5.42046 0.416748 2.91775 2.91775C0.416748 5.42216 0.416748 9.447 0.416748 17.5001C0.416748 25.5532 0.416748 29.5814 2.91775 32.0824ZM29.0313 24.3334C29.0313 24.6732 28.8963 24.9991 28.6561 25.2394C28.4158 25.4797 28.0899 25.6147 27.7501 25.6147H7.25008C6.91027 25.6147 6.58438 25.4797 6.3441 25.2394C6.10382 24.9991 5.96883 24.6732 5.96883 24.3334C5.96883 23.9936 6.10382 23.6677 6.3441 23.4274C6.58438 23.1872 6.91027 23.0522 7.25008 23.0522H27.7501C28.0899 23.0522 28.4158 23.1872 28.6561 23.4274C28.8963 23.6677 29.0313 23.9936 29.0313 24.3334ZM27.7501 18.7813C28.0899 18.7813 28.4158 18.6463 28.6561 18.4061C28.8963 18.1658 29.0313 17.8399 29.0313 17.5001C29.0313 17.1603 28.8963 16.8344 28.6561 16.5941C28.4158 16.3538 28.0899 16.2188 27.7501 16.2188H7.25008C6.91027 16.2188 6.58438 16.3538 6.3441 16.5941C6.10382 16.8344 5.96883 17.1603 5.96883 17.5001C5.96883 17.8399 6.10382 18.1658 6.3441 18.4061C6.58438 18.6463 6.91027 18.7813 7.25008 18.7813H27.7501ZM29.0313 10.6667C29.0313 11.0066 28.8963 11.3324 28.6561 11.5727C28.4158 11.813 28.0899 11.948 27.7501 11.948H7.25008C6.91027 11.948 6.58438 11.813 6.3441 11.5727C6.10382 11.3324 5.96883 11.0066 5.96883 10.6667C5.96883 10.3269 6.10382 10.001 6.3441 9.76077C6.58438 9.52049 6.91027 9.3855 7.25008 9.3855H27.7501C28.0899 9.3855 28.4158 9.52049 28.6561 9.76077C28.8963 10.001 29.0313 10.3269 29.0313 10.6667Z"
                fill="#1CBAAB"
              />
            </svg>
          </div>

          {/* modal */}

          {

            openHeaderModal &&

            <div className="absolute flex-col gap-20 items-center bg-white box-shadow1 p-5 rounded-xl text-center gap-y-4">
              <div
                className="text-sm mt-2 text-dark-blue-1 font-light cursor-pointer"
                onClick={(e) => handleScrollToSection(e, "spotlight")}
              >
                Showcase
              </div>

              {/* <a href="/pricing">
              <div
                className="text-sm mt-2 text-dark-blue-1 font-light cursor-pointer"
              >
                Pricing
              </div>
              </a> */}
              <a href="#whoFutrIsFor">
                <div
                  className="text-sm mt-2 text-dark-blue-1 font-light cursor-pointer"
                  onClick={(e) => handleScrollToSection(e, "whoFutrIsFor")}
                >
                  All About the futr
                </div>
              </a>
              <div
                className="text-sm mt-2 text-dark-blue-1 font-light cursor-pointer"
                onClick={joinNowHandler}
              >
                Join Now
              </div>
              {/* <div>
                  <button className="bg-sea-green text-white text-xl font-light border-none rounded-2xl px-10 py-4">Sign In</button>
              </div> */}
            </div>
          }




        </div>
        <div className="w-full">
          <img src="assets/FutrLogo.png" alt="brand-logo" />
        </div>
      </div>

      {/* LOGIN FORM */}

      <div id="loginForm" className="mb-10">
        <LoginForm />
      </div>

      {/* IT'S TIME FOR FUTR */}

      {/* <div className="py-10 relative">
        <div className="w-full bg-yellow-1 flex flex-col justify-center items-center gap-5 lg:px-40 py-20 m-auto relative">
    
          <div className="text-white font-bold text-2xl text-center">
            It's time for futr
          </div>

          <div className="text-dark-blue-1 text-2xl md:text-5xl text-center lg:w-1/2 font-extrabold mx-auto leading-snug tracking-wide">
            It is not the strongest of the species that survives, nor the most intelligent. It is the one most adaptable to change.
          </div>

          <div className="text-dark-blue-1 text-xl text-center font-poppins">
            - Charles Darwin
          </div>
        </div>
      </div> */}

<div style={{zIndex:0}} className="relative flex flex-col items-center justify-center gap-5 mt-100 bg-yellow-1 py-20 p-10">
        <img className="absolute top-30 md:top-52 lg:top-20 sm:left-5 lg:left-[340px] -z-10" src="/assets/hugeCommas.png" alt="commas"/>
        <span className="text-white font-bold text-2xl text-center">
          It&apos;s time for futr
        </span>
        <div
          style={{ textTransform: "none", lineHeight: "70px" }}
          className="text-dark-blue-1 text-2xl md:text-5xl text-center lg:w-1/2 font-extrabold mx-auto leading-loose tracking-wide"
        >
          It is not the strongest of the species that survives, nor the most
          intelligent. It is the one most adaptable to change.
        </div>
        <span className="text-dark-blue-1 text-xl text-center font-poppins">
          - Charles Darwin
        </span>
      </div>



      {/* CULTURE OF TECH */}

      <div className="py-10 lg:py-32">
        <div className="xs:flex-col lg:flex mx-auto w-10/12">
          <div className="flex-col flex gap-5 lg:w-1/2 justify-center">
            <div className="text-4xl text-center text-dark-blue-1 font-bold md:text-5xl md:leading-[60px] lg:text-start lg:text-7xl lg:leading-[86px]">
              Reimagining the culture of tech
            </div>

            <div className="m-auto text-dark-blue-1 text-sm leading-5 font-normal text-center w-full lg:m-0 md:text-2xl lg:text-start lg:leading-9">
              futr connect empowers cutting-edge cybersecurity, AI startups, and growth-stage tech companies by connecting visionary solutions with clients. Through AI-driven content, democratized data, and seamless collaboration, we simplify the tech experience and open a powerful new revenue channel.
            </div>

            <div
              role="button"
              className="flex justify-center p-3 m-auto rounded-full bg-aqua-1 text-white w-full
            font-bold sm:w-2/3 md:text-md lg:text-xl lg:m-0 lg:w-11/12 lg:p-5 "
              onClick={joinNowHandler}
            >
              Join now
            </div>
          </div>

          <div>
            <img src="assets/culture-of-tech.png" />
          </div>
        </div>
      </div>

      {/* WHO FUTR IS FOR */}

      <div
        className="bg-gradient-to-b from-[#F5F5F5] to-[#EEF9FB] py-10 lg:py-32"
        id={"whoFutrIsFor"}
      >
        <div className="xs:flex-col lg:flex mx-auto w-10/12">
          <div>
            <img src="assets/who-futr-is-for.png" />
          </div>
          <div className="flex flex-col gap-5 w-full lg:w-1/2 justify-center text-center lg:text-start">

            <div className="text-4xl md:text-5xl lg:text-7xl text-dark-blue-1 font-bold lg:leading-[86px] md:leading-[60px]">
              Who futr is for
            </div>

            <div className="text-grey-13 text-sm leading-5 md:text-2xl md:leading-9 font-normal">
              futr connect is designed for tech innovators and businesses seeking cutting-edge cybersecurity and AI solutions. By bringing together forward-thinking companies, we create a high-quality network that simplifies discovery, fosters direct connections, and empowers the next generation of tech leaders.
            </div>
            <div
              role="button"
              className="flex justify-center p-3 m-auto rounded-full bg-aqua-1 text-white w-full
            font-bold sm:w-2/3 md:text-md lg:text-xl lg:m-0 lg:w-11/12 lg:p-5 "
              onClick={joinNowHandler}
            >
              Join now
            </div>
          </div>


        </div>
      </div>

      {/* SPOTLIGHT ON INNOVATION */}

      <div className="py-10 lg:py-32" id={"spotlight"}>
        <div className="flex flex-col w-10/12 items-center mx-auto gap-3">
          <div className="text-sm lg:text-[32px] text-dark-blue-1 font-bold lg:leading-[48px]">
            Spotlight on innovation
          </div>
          <div className="flex flex-col gap-8 items-center">
            <div className="text-4xl text-center text-dark-blue-1 font-bold md:text-5xl md:leading-[60px] lg:text-7xl lg:leading-[86px]">
              futr connect’s featured tech showcase
            </div>
            <div className="text-dark-blue-1 text-sm lg:text-2xl font-light leading-5 lg:leading-9 text-center w-full lg:w-8/12">
              Explore futr connect's curated platform, spotlighting the best in cybersecurity and AI. Discover groundbreaking solutions, pioneering products, and the ambitious companies shaping the future. It's more than a showcase - it's a pathway to the next era of tech solutions.
            </div>
            <div className="">

              <img src="/assets/SpotlightImg.png"/>
             
            </div>
          </div>
        </div>
      </div>

      {/* FUTR TV */}

      <div className="bg-gradient-to-b from-[#F5F5F5] to-[#EEF9FB] py-10 lg:py-40">
        <div className="xs:flex-col lg:flex mx-auto w-10/12">
          <div className="flex flex-col gap-5 w-full lg:w-5/6 justify-center">
            <div className="text-sm lg:text-[32px] text-dark-blue-1 leading-[48px] font-bold m-auto lg:m-0">
              As seen on FUTR.tv
            </div>

            <div className="text-3xl text-center lg:text-start text-dark-blue-1 font-bold md:text-5xl md:leading-[60px] lg:text-7xl lg:leading-[86px]">
              Startups, innovation, culture & the business of emerging tech
            </div>

            <div className="text-dark-blue-1 text-sm lg:text-2xl font-normal leading-5 lg:leading-9 text-center lg:text-start w-full lg:w-4/5">
              FUTR.tv emerged from the pandemic, focusing on startups, innovation, culture and the business of emerging tech through weekly podcasts. Hosted by Chris Brandt, with Sandesh Patel as his occasional sidekick, the show dives into conversations with industry leaders and visionary thinkers.


            </div>
            <a target="_blank" href="https://futr.tv/">

              <div
                role="button"
                className="flex justify-center p-3 m-auto rounded-full bg-aqua-1 text-white w-full
            font-bold sm:w-2/3 md:text-md lg:text-xl lg:m-0 lg:w-10/12 lg:p-5 "
              >
                Check out FUTR.tv
              </div>
            </a>
          </div>

          <div>
            <img src="assets/futr-tv.png" className="w-full" />
          </div>
        </div>
      </div>

      {/* BENEFITS */}

      <div className="bg-yellow-1 py-10 lg:py-32">
        <div className="xs:flex-col lg:w-10/12 lg:flex m-auto lg:justify-between">
          <div className="flex flex-col lg:w-1/2 gap-5 justify-center text-center lg:text-start">
            <div className="flex flex-col">
              <div className="text-dark-blue-1 font-bold text-md lg:text-2xl">
                The benefits of futr connect
              </div>
              <div className="text-dark-blue-1 font-bold text-4xl lg:text-5xl lg:leading-[57px]">
                For tech companies:
              </div>
            </div>
            <div className="text-dark-blue-1 text-sm lg:text-2xl lg:leading-[48px] lg:w-3/4 font-normal">
              <ul className="list-disc list-outside text-start pl-5 lg:pl-0">
                <li> Gain targeted exposure to qualified buyers in cybersecurity and AI.</li>
                <li>Connect directly with businesses ready to engage.</li>
                <li>Showcase your products and solutions on an exclusive, high-quality platform.</li>
                <li>Gather valuable, real-time feedback and insights.</li>
                <li>Strengthen your brand reputation within a curated network focused on innovation and market impact.</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col lg:w-1/2 gap-5 justify-center text-center lg:text-start mt-10">
            <div className="flex flex-col">
              <div className="text-dark-blue-1 font-bold text-4xl lg:text-5xl lg:leading-[57px]">
                For tech seekers:
              </div>
            </div>
            <div className="text-dark-blue-1 text-sm lg:text-2xl lg:leading-[48px] lg:w-3/4 font-normal">
              <ul className="list-disc list-outside text-start pl-5 lg:pl-0">
                <li> Access top cybersecurity and AI solutions from vetted innovators.</li>
                <li>Discover new tech without the noise of traditional sales.</li>
                <li>Engage directly with forward-thinking tech creators.</li>
                <li>Stay ahead with insights into the latest trends and breakthroughs.</li>
                <li>Find precise solutions tailored to your unique tech needs.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />

      <ToastContainer />
    </div>
  );
}

export default HomePage;
