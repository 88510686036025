import { useNavigate } from "react-router-dom";
import { getOnBoardCookie, getToken } from "../../auth/cookies";
import React from "react";

function Footer() {
  const navigate = useNavigate()
  const handleScrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/")
    }
  };
  return (
    <>
      {/* Desktop view */}

      <div className="bg-dark-blue-1 md:flex xs:flex-col justify-between px-10 md:px-32 py-10 hidden mt-auto w-full">
        <div className="flex flex-col gap-10 w-full">
          <div className="flex flex-col">
            <div className="">
              <img src="assets/FutrLogo.png" className="w-[292px]" />
            </div>

            <div className="flex justify-between">

              <div className="flex gap-5">

                {getOnBoardCookie() ? (
                  ""
                ) : (
                  <div className="w-full">
                    <button
                      className="btn bg-sea-green rounded-full px-40
                                    py-3 text-white w-full font-semibold"
                      onClick={(e) => {
                        handleScrollToSection(e, "loginForm");
                      }}
                    >
                      Sign In or Join
                    </button>
                  </div>
                )}


                <div className="flex md:flex-row flex-wrap lg:flex-nowrap xs:flex-col text-yellow-1 gap-10 w-full">
                  <a target="_blank" href="https://web-futr-landing.vercel.app/contact">

                    <div className="font-light tracking-wider leading-9 text-2xl">
                      Contact
                    </div>
                  </a>
                  {/* <div className='font-light tracking-wider leading-9 text-2xl'>
                            Help Centre
                        </div> */}

                  <a href={getOnBoardCookie() ? (
                    "https://futr-frontend-git-stag-devideaushers-projects.vercel.app/companyProfile/83"
                  ) : "#whoFutrIsFor"} target={getOnBoardCookie() ? "_blank" : "_self"}>
                    <div className="font-light tracking-wider leading-9 text-2xl">
                      About
                    </div>
                  </a>
                  <a href="https://futr.tv/" target="_blank">
                    <div className="font-light tracking-wider leading-9 text-2xl">
                      FUTR.tv
                    </div>
                  </a>
                  <a
                    href="https://static.futrconnect.io/futr_connect_privacy_policy.html"
                    target="_blank"
                  >
                    <div className="font-light tracking-wider leading-9 text-2xl cursor-pointer whitespace-nowrap">
                      Privacy Policy
                    </div>
                  </a>
                  {/* <div className='font-light tracking-wider leading-9 text-2xl'>
                            Press
                        </div> */}
                </div>

              </div>
              <div>
                <img src="assets/linkedin-logo-footer.svg" />
              </div>


            </div>


          </div>

          <div className="text-white text-xl font-normal leading-[30px]">
            © 2023 All Rights Reserved futr
          </div>
        </div>

      </div>

      {/* Mobile view */}

      <div className="bg-dark-blue-1 flex-col justify-between px-10 py-10 md:hidden mt-auto">
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <div className="flex justify-center">
              <img src="assets/FutrLogo.png" className="w-2/3 sm:w-1/3" />
            </div>

            <div className="flex flex-col gap-5 justify-center">
              <div className="flex justify-center">
                <img src="assets/linkedin-logo-footer.svg" />
              </div>

              <div className="flex flex-wrap justify-center text-yellow-1 gap-3">
              <a target="_blank" href="https://web-futr-landing.vercel.app/contact">
                <div className="font-light tracking-wider leading-9 text-md">
                  Contact
                </div>
              </a>
                {/* <div className='font-light tracking-wider leading-9 text-2xl'>
                            Help Centre
                        </div> */}
                <a href={getOnBoardCookie() ? (
                  "https://futr-frontend-git-stag-devideaushers-projects.vercel.app/companyProfile/83"
                ) : "#whoFutrIsFor"} target={getOnBoardCookie() ? "_blank" : "_self"}>
                  <div className="font-light tracking-wider leading-9 text-md">
                    About
                  </div>
                </a>
                <a href="https://futr.tv/" target="_blank">
                  <div className="font-light tracking-wider leading-9 text-md">
                   FUTR.tv
                  </div>
                </a>
                <a
                  href="https://static.futrconnect.io/futr_connect_privacy_policy.html"
                  target="_blank"
                >
                  <div className="font-light tracking-wider leading-9 text-md cursor-pointer">
                    Privacy Policy
                  </div>
                </a>

                {/* <div className='font-light tracking-wider leading-9 text-2xl'>
                            Press
                        </div> */}
              </div>
            </div>

            {getOnBoardCookie() ? (
              ""
            ) : (
              <div className="w-full sm:w-1/3 sm:m-auto">
                <button
                  className="btn bg-sea-green rounded-full px-5 py-3 text-white w-full font-semibold"
                  onClick={(e) => {
                    handleScrollToSection(e, "loginForm");
                  }}
                >
                  Sign In or Join
                </button>
              </div>
            )}
          </div>

          <div className="text-white text-center text-md font-normal leading-[30px]">
            © 2023 All Rights Reserved futr
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
