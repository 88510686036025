import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../Context/StateContext";
import { toastError } from "../../../utils/Toasters";
import { getMethod } from "../../../utils/apiMethod";
import StatsGraph from "./StatsGraph";
import { camelToTitleCase } from "../../../utils/stringNumberFormat";

const Analysis = () => {
  const { setLoading } = useContext(StateContext);
  const [category, setCategory] = useState("followers");
  const [stats, setStats] = useState({});
  const [openDropdown, setOpenDropdown] = useState(false);
  const [duration, setDuration] = useState("allTime");

  useEffect(() => {
    setLoading(true);

    getMethod(`analytics/stats?duration=${duration}`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setStats(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, [duration]);

  return (
    <div className="flex flex-col">
      {/* Sort Dropdown */}
      <div className="flex items-center mb-5">
        <p>Time Range: </p>
        <div
          className="ml-2 p-2 border border-grey-7 rounded-md cursor-pointer flex items-center shadow-sm relative"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
         {duration === "last7Days" ? "Last 7 Days" : camelToTitleCase(duration)}
          <svg width="16" height="16" className="ml-2" viewBox="0 0 23 22">
            {/* Dropdown Arrow */}
            <path
              d="M11.8 14.4L5.6 8.2C5.3 7.9 5.1 7.4 5.4 7.1C5.7 6.8 6.2 7 6.5 7.4L12 12.9L17.5 7.4C17.8 7 18.3 6.8 18.6 7.1C18.9 7.4 18.7 7.9 18.4 8.2L12.2 14.4C12 14.6 11.8 14.6 11.8 14.4Z"
              fill="#222222"
            />
          </svg>
          {/* Dropdown */}
          {openDropdown && (
            <div className="px-2 py-2 border border-grey-7 bg-white rounded-md cursor-pointer flex flex-col items-left shadow-sm absolute -bottom-52 gap-2 w-28 z-30">
            {/* <div onClick={()=>setDuration("thisWeek")}>
             This Week
            </div> */}
            <div onClick={()=>setDuration("last7Days")}>
            Last 7 Days
            </div>
            <div onClick={()=>setDuration("last15Days")}>
            Last 15 Days
            </div>
            <div onClick={()=>setDuration("last30Days")}>
            Last 30 Days
            </div>
            <div onClick={()=>setDuration("last60Days")}>
            Last 60 Days
            </div>
            <div onClick={()=>setDuration("last90Days")}>
            Last 90 Days
            </div>
            <div onClick={()=>setDuration("allTime")}>
            All Time
            </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col rounded-xl box-shadow4">
        {/* TOP INFO CARDS */}
        <div className="flex">
          <div
            className={`p-4 w-1/5 cursor-pointer shadow-sm bg-grey-1 ${
              category === "views" &&
              "bg-white rounded-tl-2xl border-t-4 border-t-sea-green"
            }`}
            onClick={() => setCategory("views")}
          >
            <p className="text-xs md:text-sm text-gray-500">PROFILE VIEWS</p>
            <p className="ttext-xs md:text-2xl  font-bold">
              {stats?.totalViews}
            </p>
          </div>
          <div
            className={`p-4 w-1/5 cursor-pointer shadow-sm bg-grey-1 ${
              category === "followers" &&
              "bg-white border-t-4  border-t-sea-green"
            }`}
            onClick={() => setCategory("followers")}
          >
            <p className="text-xs md:text-sm text-gray-500">TOTAL FOLLOWERS</p>
            <p className="text-xs md:text-2xl  font-bold">
              {stats?.totalFollowers}
            </p>
          </div>
          <div
            className={`p-4 w-1/5 cursor-pointer shadow-sm bg-grey-1 ${
              category === "timeSpent" &&
              "bg-white border-t-4  border-t-sea-green"
            }`}
            onClick={() => setCategory("timeSpent")}
          >
            <p className="text-xs md:text-sm text-gray-500">AVERAGE TIME SPENT</p>
            <p className="text-xs md:text-xl font-bold">
              {stats?.avgSessionTime}
            </p>
          </div>
          <div
            className={`p-4 w-1/5 cursor-pointer shadow-sm bg-grey-1 ${
              category === "bounceRate" &&
              "bg-white border-t-4  border-t-sea-green"
            }`}
            onClick={() => setCategory("bounceRate")}
          >
            <p className="text-xs md:text-sm text-gray-500">BOUNCE RATE</p>
            <p className="text-xs md:text-2xl  font-bold">
              {stats?.bounceRate} %
            </p>
          </div>
          <div
            className={`p-4 w-1/5 cursor-pointer shadow-sm bg-grey-1 ${
              category === "meetingsBooked" &&
              "bg-white rounded-tr-2xl border-t-4 border-t-sea-green"
            }`}
            onClick={() => setCategory("meetingsBooked")}
          >
            <p className="text-xs md:text-sm text-gray-500">BOOK MEETING CLICKS</p>
            <p className="text-xs md:text-2xl font-bold">
              {stats?.bookMeeting}
            </p>
          </div>
        </div>

        {/* LINE CHART */}
        <StatsGraph category={category} />
      </div>
    </div>
  );
};

export default Analysis;
