import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import useFirebaseAuth from '../../auth/useFirebaseAuth';

function SignInWithLinkedIn() {

  const navigate = useNavigate()

    const {signInWithCustomToken} = useFirebaseAuth();
    // const BASE_URL = 'https://api.dev.futrconnect.com/v1/'
    const BASE_URL = process.env.REACT_APP_BASE_URL

    const submitHandler = async() => {
        try{

            let response = await fetch(`${process.env.REACT_APP_BASE_URL}auth/linkedin?type=company&env=${process.env.REACT_APP_ENV}`) // backend API for linkedin
            let result = await response.json();

            window.open(result?.linkedInAuthUrl);

          
          
            
        } catch(e){

        }
    }

    const handleLinkedInCallback = async (code) => {
        try {
          const response = await fetch(`${BASE_URL}/auth/linkedInCallback?code=${code}`);
          const { firebaseToken } = response.data;
          console.log("FIREBASE TOKEN : ", firebaseToken)
          const authUser = await signInWithCustomToken(firebaseToken);
          // set cookies

          console.log('User signed in with LinkedIn!');
        } catch (error) {
          console.error('Error during LinkedIn login:', error);
        }
      };

    useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      handleLinkedInCallback(code);
    }
     
    }, [])
    

  return (
    <div className="text-xl text-black font-base cursor-pointer" onClick={submitHandler}>
                Sign in with LinkedIn
              </div>
  )
}

export default SignInWithLinkedIn