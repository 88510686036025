import React, { useEffect, useRef, useState } from 'react'
import useFirebaseAuth from "../../../auth/useFirebaseAuth";
import { getMethod, patchFormMethod, patchMethod, postFormMethod } from '../../../utils/apiMethod';
import { toastError, toastSuccess } from '../../../utils/Toasters';
import LocalLoader from '../LocalLoader';
import SpinLoader from '../SpinLoader';

function EditAddress({addressId, onClickClose, setRefresher}) {
  
    const modalStyle =  {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1000",
     }

     const [localLoader, setLocalLoader] = useState(false)
     const [updateLoader, setUpdateLoader] = useState(false)

     const [address, setAddress] = useState(
        { address: '', zipCode: '', city: '', state: '' },
      );
     

      const handleAddressChange = (e) => {
       
        setAddress({...address, [e.currentTarget.name]: e.target.value})
    
    
      };

      const handleZipCodeChange = (e) => {
        const value = e.target.value;
        if (/^[a-zA-Z0-9\s\-]*$/.test(value) && value.length <= 10) {
          setAddress({ ...address, zipCode: value });
        }
      };
    

     const submitHandler = (e) => {
        e.preventDefault();
        setUpdateLoader(true)
         
            var raw = {
                address: address?.address,
                city: address?.city,
                state: address?.state,
                zipCode: address?.zipCode
            }
           
  
            patchMethod(`company/updateAddress/${addressId}`, raw)
            .then((res) => {
              setUpdateLoader(false)
              if (res.status == true) {
        
                console.log("Address RES : ",res);   
                toastSuccess(res?.message)
                setRefresher(prev => !prev)
                onClickClose();
               
                
               
              }else{
                setUpdateLoader(false)
                toastError(res.message)
              }
            })
            .catch((error) => {
              // setLoading(false);
              setUpdateLoader(false)
              toastError(error.message);
              console.log("error.message: ", error.message);
            });
        
          
      };

      useEffect(() => {

        setLocalLoader(true)
 
        getMethod(`company/viewAddress/${addressId}`)
          .then((res) => {
      
            if (res?.status) {
              console.log("Response from getAddress: ", res);
              setAddress({...res?.data})
              setLocalLoader(false)
             
    
            }else{
              setLocalLoader(false)
              toastError(res.message);
            }
          })
          .catch((error) => {
            setLocalLoader(false)
           
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      }, []);

  return (
    <div className='modal-wrapper'>
    
    <div className='flex flex-col bg-white rounded-2xl w-10/12 lg:w-2/3 p-10  gap-5' style={modalStyle} >
      <div className='flex justify-between items-center mb-5 w-full '>
      <div className="text-2xl  capitalize font-semibold">
              Edit Address
          </div>
        <div role="button" className='text-sea-green
         border border-sea-green px-5 py-3 rounded-md'
         onClick={onClickClose}
        >
          Close
        </div>
      </div>

      {
        localLoader ? <LocalLoader/>: 

      <div className="flex flex-col gap-2 rounded-xl border border-grey-8 p-3">
                   

                   <div className="flex items-center rounded-lg border border-grey-7 h-12">
                     <input
                       className="focus:border-transparent outline-none mx-5 w-full h-full"
                       placeholder="Enter Address"
                       value={address?.address}
                       onChange={e => handleAddressChange(e)}
                       name="address"
                     />
                   </div>

                   <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
                     <div className="flex items-center rounded-lg border border-grey-7 h-12">
                       <input
                         className="focus:border-transparent outline-none mx-5 w-full h-full text-xs md:text-normal"
                         placeholder="Enter City"
                         value={address?.city}
                         onChange={e => handleAddressChange(e)}
                         name="city"
                       />
                     </div>
                     <div className="flex items-center rounded-lg border border-grey-7 h-12">
                       <input
                         className="focus:border-transparent outline-none mx-5 w-full h-full text-xs md:text-normal"
                         placeholder="Enter State"
                         value={address?.state}
                         onChange={e => handleAddressChange(e)}
                         name="state"
                       />
                     </div>
                     <div className="flex items-center rounded-lg border border-grey-7 h-12">
                       <input
                         className="focus:border-transparent outline-none mx-5 w-full h-full text-xs md:text-normal"
                         placeholder="Enter Zip/postal code"
                         value={address?.zipCode}
                         onChange={e => handleZipCodeChange(e)}
                         name="zipCode"
                         type=""
                         maxLength={10}
                       />
                     </div>
                     
                   </div>
       </div>
      }
  

        <div className='flex lg:justify-end w-full'>

            <button className='bg-sea-green text-white text-xl py-3 w-full lg:w-3/12 rounded-md'
             onClick={submitHandler}>
               {updateLoader?
               <div>
                 <SpinLoader/>
                 </div>:"Update"}
               </button>

        </div>
      
    </div>
  </div>
  )
}

export default EditAddress
